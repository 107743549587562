.transcript-module__datetime___2dc83 {
  box-sizing: border-box;
  display: block;
  margin-right: 5px;
}

.transcript-module__time_zone___U1A9M {
  font-weight: 700;
}

.transcript-module__assessments_section {
  margin-bottom: 20px;
}

@media only screen and (max-width: 667px) {
  .transcript-module__enrollments_enrollement_date_col___3J2F1 {
    width: auto;
  }

  .transcript-module__enrollments_completion_date_col___3yZ8U {
    width: auto;
  }

  .transcript-module__datetime___2dc83 {
    display: inline-block;
  }

  .transcript-module__time_zone___U1A9M {
    display: block;
    margin: 0 20px;
  }

  .banner-module__banner___IJO0Z {
    align-items: unset;
  }

  .banner-title-module__title___1mbDk {
    text-align: center;
  }
}
