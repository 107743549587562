.category-list__category {
  cursor: pointer;
}

.banner__back_arrow {
  color: #ab192d !important;
}

@media only screen and (max-width: 667px) {
  .resources-module__content___18wl4 {
    flex-direction: column-reverse;
  }
}
  