.card-module-container {
  flex: 1 1;
  padding: 30px 40px;
}

@media screen and (max-width: 667px) {
  .flex-direction-col {
    flex-direction: column;
  }
  .payment-heading-no_padding {
    padding: 0;
  }
  .card-module-container {
    padding: 30px 20px;
  }
}
