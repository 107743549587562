.dashboard-container-module__section___2OZXP {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.dashboard-tile-container-margin {
  margin: 0 -9px;
}

.dashboard-tile-wrapper-module__tile___1_5Jt {
  flex-basis: 163px;
  margin: 9px !important; /* horizontal margin here should be same as .dashboard-tile-container-margin */
  background-color: #cf102d;
}

.private-dashboard-module__ribbon_wrapper___2Giid {
  padding-top: 20px;
}

.private-dashboard-module__no_top_padding___fEvA2 {
  padding-top: 0;
}
