.language-button-module__language_selector___X67Ay.language-button__language_selector {
  flex-grow: 0;
  flex: 1;
}

.footer-module__footer___2vz5r.footer__footer {
  display: flex;
  justify-content: space-between;
}

.footer-module__custom_text___3ZYJx,
.footer-module__custom_text_empty___2feTQ {
  flex: auto;
  padding: 0;
}

.footer__custom_footer {
  flex-grow: 1;
}

.footer__custom_text p {
  margin: 0;
  padding: 20px;
}

.icon-endeavor {
  background: url(../assets/endeavor-logo-white-35.png);
  height: 35px;
  width: 35px;
}

.footer__copyright {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

@media only screen and (max-width: 667px) {
  .footer__custom_footer {
    display: flex;
    padding: 0;
    flex-direction: column;
    text-align: center;
  }

  .footer__custom_text p {
    padding: 10px 0px;
  }

  .footer-module__footer___2vz5r.footer__footer {
    padding: 5px 20px;
    flex-direction: row;
    align-items: center;
    height: auto;
  }
}
