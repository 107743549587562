.shopping-cart-summary-module__item_price___wf9Py {
  white-space: nowrap;
}

@media screen and (max-width: 667px) {
  .flex-direction-col {
    flex-direction: column;
  }
  .cart-account-module__content_cart___3vclY {
    display: unset;
    width: auto;
    margin: 0 20px;
  }
  .cart-shipping-module__submit_btn___1ektB {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 667px) {
  .cart-account-module__content_cart___3vclY {
    width: 400px;
  }
}
