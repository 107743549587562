.instructor-led-course-card-module__info___2wQ0Y {
  white-space: normal;
}

.instructor-led-course-card-module__info_session_time___2ThXT {
  font-size: 18px;
}

.card-module__card_bottom___2pcXZ {
  cursor: unset;
}

.card-module__name___1kcxc:hover {
  cursor: pointer;
  text-decoration: underline;
}
