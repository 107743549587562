.banner {
  background-image: url(../assets/banner.jpg);
  background-size: cover;
  background-position: center;
}

.header-module__brand_logo___1lP3u {
  height: auto;
}

.banner-2 {
  background-image: url(../assets/banner-2.jpg);
}

.tab-list-module__dropdown_title___2bigI > div:first-of-type,
.tab-list-module__dropdown_option___1tlVL {
  font-size: 16px;
}

@media screen and (min-width: 481px) and (max-width: 505px) {
  .welcome-dashboard-tile-module__username___2cp73 {
    font-size: 37.5px;
  }
}
