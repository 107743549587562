.updated_loader_container {
  position: relative;
  display: inline-table;
}

.updated_button_module__loader {
  animation: button-module__buttonLoader___Kwf0I 1.3s linear infinite;
  border-radius: 50%;
  color: #fff;
  height: 6px;
  width: 6px;
}
