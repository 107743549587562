@media only screen and (max-width: 667px) {
  .mobileView {
    display: block;
  }
  .browserView {
    display: none;
  }
}
@media only screen and (min-width: 667px) {
  .mobileView {
    display: none;
  }
  .browserView {
    display: block;
  }
}
