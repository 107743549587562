.print-transcript-module__enrollments_score_col___nLvGu {
  min-width: 75px;
}

.print-transcript-module__datetime___35BKb {
  box-sizing: border-box;
  display: block;
  margin-right: 5px;
}

.print-transcript-module__time_zone___yJiuE {
  display: block;
  font-weight: 700;
  margin-top: 10px;
}

.print-transcript-module__enrollments_credits_col___3hO35 {
  min-width: 100px;
  width: 100px;
}
@media only screen and (max-width: 667px) {
  .mobileView {
    display: block;
  }
  .print-transcript-module__enrollments_enrollement_date_col___3_eBv {
    width: auto;
  }
  .print-transcript-module__enrollments_completion_date_col___2ZHZL {
    width: auto;
  }
  .print-transcript-module__datetime___35BKb {
    display: inline-block;
  }
}
@media only screen and (min-width: 667px) {
  .mobileView {
    display: none;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
  }

  @page {
    size: auto;
    margin: 12mm;
  }
  .print-transcript__row {
    background-color: #ffffff !important;
  }
  .sortable-table__table_row_header {
    background-color: #ffffff !important;
  }
  .print-transcript {
    display: none;
  }
}
