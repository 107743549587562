.textInputComp {
  border: 1px solid #e3e3e3;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  color: #7e8082;
  font-family: inherit;
  font-size: 12px;
  line-height: normal;
  outline: none;
  padding: 6px;
  width: 100%;
}
