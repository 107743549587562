@media screen and (max-width: 667px) {
  .instructor-led-course-module__content_left___wfpxJ > .online-course-module__course_progress_mobile___aLBPR {
    padding-left: 40px;
  }
}
#cometchat__widget,
.app__wrapper,
.css-1ifz4g8 {
  max-height: 1px !important;
  height: 1px !important;
}
