.action-button-module__btn___3zkzb {
  min-height: 30px;
}

.panel-module__panel_header_link___j5xTN:hover .panel-module-no_text_decoration {
  text-decoration: none;
}

.panel-module-default_cursor {
  cursor: auto;
}
