.category-list__category {
  cursor: pointer;
}

.banner__back_arrow {
  color: #ab192d !important;
}

@media only screen and (max-width: 768px) {
  .catalog-module__cards_container___35rgq {
    justify-content: end;
    margin: 0px;
  }
}
