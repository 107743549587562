.padding-left-zero {
  padding-left: 0;
}

.height-40 {
  height: 40px;
}

.link-style {
  color: #ab192d;
  cursor: pointer;
}
