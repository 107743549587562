@media only screen and (min-width: 768px) {
  .form-wrapper-reset-password {
    display: flex;
    flex-direction: column;
    width: 30%;
    border-radius: 4px;
    height: 81%;
    width: 35%;
    padding: 40px;
    background-color: hsla(0, 0%, 100%, 0.9);
  }
}

.forgot-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  margin-bottom: 10px;
  color: #303335;
}

.forgot-subtitle {
  font-size: 14px;
  color: #303335;
  text-align: center;
  margin: 10px;
}

.forgot-password-success {
  background: hsla(0, 0%, 100%, 0.95);
  border-radius: 5px;
  padding: 40px;
}

.forgot-password-login-link {
  display: flex;
  justify-content: flex-end;
}

#reset-password-confirmation {
  height: 20px;
}

#hiddenusername {
  display: none;
}
