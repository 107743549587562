.transcript-module__enrollments_score_col___KMO2B {
  min-width: 95px;
}

@media only screen and (max-width: 667px) {
  .transcript-module__enrollments_score_col___KMO2B {
    width: auto;
  }

  .transcript-module__enrollments_credits_col___3FKKZ {
    width: auto;
  }
}

@media print {
  .transcript__row {
    background-color: #ffffff !important;
  }

  .sortable-table__table_row_header {
    background-color: #ffffff !important;
  }
}
