.custom-poster.image-carousel-module__poster_image___uVEIF {
  min-width: 400px;
  min-height: 300px;
  width: unset;
}

.image-carousel-module__images_track___YpVBj {
  justify-content: center;
}

.image-carousel-module__images_slider___2nH1R {
  justify-content: center !important;
}

.view-options-display-none {
  display: none;
}
