.category-list__category {
  cursor: pointer;
}

.course-card-margin {
  margin: 0 20px 20px 0;
}

.banner__back_arrow {
  color: #ab192d !important;
}

@media screen and (max-width: 768px) {
  .catalog-module__cards_container___35rgq {
    justify-content: space-evenly;
    margin: 0 -20px;
  }

  .course-card-margin {
    margin: 0 0 20px;
  }
}
